/* eslint-disable @typescript-eslint/no-non-null-assertion */
import AuthenticationService from "@/services/authentication-service";

class BaseService {
  protected readonly baseApiUrl = process.env.VUE_APP_BASE_API_URL;

  /**
   * The fetch function sends HTTP requests with added functionality for handling authentication, error responses,
   * and different content types.
   * @param {RequestInfo} input - The `input` parameter in the `fetch` function represents the resource (URL) that you want to fetch or
   * make a request to. It can be a URL string or a `Request` object.
   * @param {RequestInit} [init] - The `init` parameter in the `fetch` function is an optional parameter of type `RequestInit`.
   * It is an object that allows you to control various settings for the HTTP request being made, such as the request method, headers,
   * body, mode, cache, credentials, and more. It provides
   * @param {string} [token] - The `token` parameter in the `fetch` function is used to provide an authentication token for making
   * authorized requests. If a `token` is not provided when calling the `fetch` function, it will attempt to retrieve a JWT token using the
   * `AuthenticationService.getJWTToken()` method. If the token is not available, the user will be redirected to the login page.
   * @returns The `fetch` function returns a Promise that resolves to a value of type `T`. The function performs a series of operations
   * including making a fetch request with optional headers, handling different HTTP status codes, checking for maintenance mode, and
   * processing different content types. The final resolved value of the Promise depends on the specific logic executed based on the
   * response from the fetch request.
   */
  fetch<T>(input: RequestInfo, init?: RequestInit, token?: string): Promise<T> {
    if (!token) {
      token = AuthenticationService.getJWTToken();
    }

    return fetch(input, {
      ...init,
      ...(token && {
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${token}`,
        },
      }),
    })
      .then((response) => {
        switch (response.status) {
          case 401:
          case 403:
            return AuthenticationService.authenticate().then((token) =>
              this.fetch<T>(input, init, token)
            );
          case 404:
            window.location.href = "/not-found";
            return Promise.resolve(undefined as unknown as T);
          case 400:
          case 500:
            response.text().then((result) => {
              console.error(result);
            });
            return Promise.resolve(undefined as unknown as T);
          case 204:
            console.log("No data was returned");
            return Promise.resolve(undefined as unknown as T);
          default:
            break;
        }

        if (!response.ok || response.status === 204) {
          return Promise.reject(response.status);
        }

        const inMaintenanceMode = response.headers.get("x-maintenance-mode");

        if (inMaintenanceMode && inMaintenanceMode === "true") {
          window.location.href = "/maintenance";
          return Promise.resolve(undefined as unknown as T);
        }

        const contentType = response.headers.get("content-type");

        switch (contentType) {
          case "application/json; charset=utf-8":
            return response.json() as Promise<T>;
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          case "application/pdf":
          case "text/csv; charset=utf-8": {
            const filename = this.getFileName(response);
            return response.blob().then((blob: Blob) => {
              try {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.setAttribute("download", filename);
                link.click();
                URL.revokeObjectURL(link.href);

                return Promise.resolve(true as unknown as T);
              } catch (e) {
                console.error("Error: ", e);
                return Promise.resolve(false as unknown as T);
              }
            });
          }
          default:
            return Promise.reject(new Error("Unsupported content type"));
        }
      })
      .catch((e: Error) => {
        console.error("Error: ", e);
        return Promise.reject(e);
      });
  }

  /**
   * Extracts the filename from the content-disposition header of a response.
   * @param {Response} response - Represents a response from a network request in the `fetch` method above.
   * @returns A string value, which is either the extracted filename from the response
   * headers if it is found, or the default value "download" if no filename is extracted.
   */
  private getFileName(response: Response): string {
    const disposition = response.headers.get("content-disposition");

    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);

      if (matches != null && matches[1]) {
        const filename = matches[1].replace(/['"]/g, "");
        return filename.trim();
      }
    }

    return "download";
  }
}

export default BaseService;
